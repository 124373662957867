'use strict';


function indexPagePathFormatter(page) {
  var match = page === 1;
  if (match) {
    return "/";
  } else {
    return "/page/" + String(page);
  }
}

function getTagPath(tag) {
  var sanitizedTag = tag.toLowerCase().replace((/ /g), "-");
  return "/blog/tags/" + sanitizedTag;
}

exports.indexPagePathFormatter = indexPagePathFormatter;
exports.getTagPath = getTagPath;
/* No side effect */
