import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';

export default function SidebarQueryImpl(props) {
  return (
    <StaticQuery
      render={props.render}
      query={graphql`
        query LayoutQuery {
          tags: allMarkdownRemark {
            edges {
              node {
                frontmatter {
                  tags
                }
              }
            }
          }
          recentPosts: allMarkdownRemark(
            filter: { frontmatter: { layout: { eq: "post" } } }
            sort: { order: DESC, fields: [frontmatter___date] }
            limit: 4
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  date(formatString: "YYYY-MM-DD")
                  tags
                }
              }
            }
          }
        }
      `}
    />
  );
}
