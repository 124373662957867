'use strict';

var Js_dict = require("bs-platform/lib/js/js_dict.js");

var profiles = Js_dict.fromArray(/* array */[
      /* tuple */[
        "envelope",
        /* record */[
          /* text */"christoph@knittel.cc",
          /* url */"mailto:christoph@knittel.cc"
        ]
      ],
      /* tuple */[
        "phone",
        /* record */[
          /* text */"+43 650 5648835",
          /* url */"tel:+436505648835"
        ]
      ],
      /* tuple */[
        "github",
        /* record */[
          /* text */"@cknitt",
          /* url */"https://github.com/cknitt"
        ]
      ],
      /* tuple */[
        "twitter",
        /* record */[
          /* text */"@cknitt",
          /* url */"https://twitter.com/cknitt"
        ]
      ],
      /* tuple */[
        "xing",
        /* record */[
          /* text */"Christoph_Knittel3",
          /* url */"https://www.xing.com/profile/Christoph_Knittel3"
        ]
      ],
      /* tuple */[
        "linkedin",
        /* record */[
          /* text */"christophknittel",
          /* url */"https://www.linkedin.com/in/christophknittel"
        ]
      ],
      /* tuple */[
        "rss",
        /* record */[
          /* text */"RSS",
          /* url */"/rss.xml"
        ]
      ]
    ]);

exports.profiles = profiles;
/* profiles Not a pure module */
