'use strict';

var React = require("react");
var Gatsby = require("gatsby");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var TagLink = require("./TagLink.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var SidebarQuery = require("./SidebarQuery.js");
var ReactTagcloud = require("react-tagcloud");
var ReactFontawesome = require("react-fontawesome");
var ReactScrollUpButton = require("react-scroll-up-button");

function renderRecentPost(post) {
  var slug = post.fields.slug;
  var frontmatter = post.frontmatter;
  var tags = frontmatter.tags;
  var title = frontmatter.title;
  var date = frontmatter.date;
  var tmp;
  if ((tags == null) || tags.length <= 0) {
    tmp = null;
  } else {
    var tag = tags[0];
    tmp = React.createElement("p", {
          className: "item-category"
        }, React.createElement(TagLink.make, {
              tag: tag
            }));
  }
  return React.createElement("li", {
              key: post.id
            }, React.createElement("div", {
                  className: "item-inner"
                }, tmp, React.createElement("p", {
                      className: "item-title"
                    }, React.createElement(Gatsby.Link, {
                          to: slug,
                          className: "title",
                          children: title
                        })), React.createElement("p", {
                      className: "item-date"
                    }, React.createElement("time", {
                          itemProp: "datePublished",
                          dateTime: date
                        }, date))));
}

function Sidebar$RecentPosts(Props) {
  var recentPosts = Props.recentPosts;
  return React.createElement("div", {
              className: "widget-wrap"
            }, React.createElement("h3", {
                  className: "widget-title"
                }, "Recent"), React.createElement("div", {
                  className: "widget"
                }, React.createElement("ul", {
                      className: "no-thumbnail",
                      id: "recent-post"
                    }, recentPosts.map(renderRecentPost))));
}

var RecentPosts = {
  renderRecentPost: renderRecentPost,
  make: Sidebar$RecentPosts
};

function renderer(tag, size, color) {
  var key = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(tag.key), tag.value);
  var style = {
    color: color,
    display: "inline-block",
    fontSize: String(size) + "px",
    margin: "0px 3px",
    verticalAlign: "middle"
  };
  return React.createElement(TagLink.make, {
              tag: tag.value,
              className: "tag-cloud-tag",
              style: style,
              key: key
            });
}

function Sidebar$TagCloud(Props) {
  var tagCounts = Props.tagCounts;
  return React.createElement("div", {
              className: "widget-wrap"
            }, React.createElement("h3", {
                  className: "widget-title"
                }, "Tag Cloud"), React.createElement("div", {
                  className: "widget tagcloud"
                }, React.createElement(ReactTagcloud.TagCloud, {
                      tags: tagCounts,
                      maxSize: 30,
                      minSize: 13,
                      shuffle: false,
                      disableRandomColor: true,
                      renderer: renderer
                    })));
}

var TagCloud = {
  renderer: renderer,
  make: Sidebar$TagCloud
};

function getRecentPosts(recentPostsData) {
  return recentPostsData.edges.map((function (edge) {
                return edge.node;
              }));
}

function getTagsWithCounts(tagData) {
  var countsMap = { };
  tagData.edges.forEach((function (edge) {
          var tags = edge.node.frontmatter.tags;
          if (tags == null) {
            return /* () */0;
          } else {
            tags.forEach((function (tag) {
                    var match = Js_dict.get(countsMap, tag);
                    var newCount = match !== undefined ? match + 1 | 0 : 1;
                    countsMap[tag] = newCount;
                    return /* () */0;
                  }));
            return /* () */0;
          }
        }));
  return Js_dict.entries(countsMap).map((function (param) {
                return {
                        value: param[0],
                        count: param[1]
                      };
              }));
}

function Sidebar(Props) {
  return React.createElement(SidebarQuery.make, {
              render: (function (data) {
                  var recentPosts = getRecentPosts(data.recentPosts);
                  var tagCounts = getTagsWithCounts(data.tags);
                  return React.createElement("aside", {
                              id: "sidebar"
                            }, React.createElement(Sidebar$RecentPosts, {
                                  recentPosts: recentPosts
                                }), React.createElement(Sidebar$TagCloud, {
                                  tagCounts: tagCounts
                                }), React.createElement(ReactScrollUpButton.default, {
                                  ContainerClassName: "toTop",
                                  TransitionClassName: "toTopVisible",
                                  children: React.createElement(ReactFontawesome, {
                                        name: "angle-up"
                                      })
                                }));
                })
            });
}

var make = Sidebar;

exports.RecentPosts = RecentPosts;
exports.TagCloud = TagCloud;
exports.getRecentPosts = getRecentPosts;
exports.getTagsWithCounts = getTagsWithCounts;
exports.make = make;
/* react Not a pure module */
