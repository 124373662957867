'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Gatsby = require("gatsby");
var Profile = require("./Profile.js");
var Sidebar = require("./Sidebar.js");
var AvatarPng = require("./avatar.png");
var ReactHelmet = require("react-helmet");
var FaviconIco = require("./favicon.ico");

var favIcon = FaviconIco;


require("font-awesome/css/font-awesome.min.css");
require("../css/style.styl");

;

var avatarImage = AvatarPng;

var mainMenuItems = /* array */[
  /* record */[
    /* title */"Home",
    /* url */"/"
  ],
  /* record */[
    /* title */"Archives",
    /* url */"/blog/archives"
  ],
  /* record */[
    /* title */"iOS Apps",
    /* url */"/apps"
  ],
  /* record */[
    /* title */"Contact",
    /* url */"/contact"
  ]
];

function renderMainNavigation(param) {
  return React.createElement("nav", {
              id: "main-nav"
            }, mainMenuItems.map((function (item) {
                    return React.createElement(Gatsby.Link, {
                                to: item[/* url */1],
                                className: "main-nav-link",
                                children: item[/* title */0],
                                key: item[/* url */1]
                              });
                  })));
}

function renderMainNavigationMobile(param) {
  return React.createElement("div", {
              className: "header-sub header-inner",
              id: "main-nav-mobile"
            }, React.createElement("table", {
                  className: "menu outer"
                }, React.createElement("tbody", undefined, React.createElement("tr", undefined, mainMenuItems.map((function (item) {
                                return React.createElement("td", {
                                            key: item[/* url */1]
                                          }, React.createElement(Gatsby.Link, {
                                                to: item[/* url */1],
                                                className: "main-nav-link",
                                                children: item[/* title */0]
                                              }));
                              }))))));
}

function Layout$Header(Props) {
  var title = Props.title;
  var onProfileButtonClick = Props.onProfileButtonClick;
  return React.createElement("header", {
              id: "header"
            }, React.createElement("div", {
                  className: "header-inner",
                  id: "header-main"
                }, React.createElement("div", {
                      className: "outer"
                    }, React.createElement(Gatsby.Link, {
                          to: "/",
                          id: "logo",
                          children: React.createElement("span", {
                                className: "site-title"
                              }, title)
                        }), renderMainNavigation(/* () */0), React.createElement("nav", {
                          id: "sub-nav"
                        }, React.createElement("div", {
                              className: "profile",
                              id: "profile-nav"
                            }, React.createElement("a", {
                                  id: "profile-anchor",
                                  role: "button",
                                  onClick: onProfileButtonClick
                                }, React.createElement("img", {
                                      className: "avatar",
                                      alt: "Avatar",
                                      src: avatarImage
                                    }), React.createElement("i", {
                                      className: "fa fa-caret-down"
                                    })))))), renderMainNavigationMobile(/* () */0));
}

var Header = {
  avatarImage: avatarImage,
  mainMenuItems: mainMenuItems,
  renderMainNavigation: renderMainNavigation,
  renderMainNavigationMobile: renderMainNavigationMobile,
  make: Layout$Header
};

var year = "2010-2019";

var author = "Christoph Knittel";

function Layout$Footer(Props) {
  return React.createElement("footer", {
              id: "footer"
            }, React.createElement("div", {
                  className: "outer"
                }, React.createElement("div", {
                      className: "inner",
                      id: "footer-info"
                    }, "© " + (String(year) + (" " + (String(author) + ". "))), React.createElement(Gatsby.Link, {
                          to: "/legal",
                          children: "Legal Notice (Impressum)"
                        }))));
}

var Footer = {
  year: year,
  author: author,
  make: Layout$Footer
};

function Layout(Props) {
  var children = Props.children;
  var match = React.useState((function () {
          return false;
        }));
  var setProfileOverlayOpen = match[1];
  return React.createElement("div", {
              id: "container",
              role: "main",
              onClick: (function (evt) {
                  evt.stopPropagation();
                  return Curry._1(setProfileOverlayOpen, (function (param) {
                                return false;
                              }));
                })
            }, React.createElement(ReactHelmet.Helmet, {
                  titleTemplate: "%s - Christoph Knittel",
                  children: null
                }, React.createElement("meta", {
                      content: "ie=edge",
                      httpEquiv: "x-ua-compatible"
                    }), React.createElement("meta", {
                      content: "width=device-width, initial-scale=1, shrink-to-fit=no",
                      name: "viewport"
                    }), React.createElement("link", {
                      href: favIcon,
                      rel: "icon",
                      type: "image/x-icon"
                    })), React.createElement(Layout$Header, {
                  title: "Christoph Knittel",
                  onProfileButtonClick: (function (evt) {
                      evt.stopPropagation();
                      return Curry._1(setProfileOverlayOpen, (function (prim) {
                                    return !prim;
                                  }));
                    })
                }), React.createElement("div", {
                  className: "outer"
                }, React.createElement(Profile.make, {
                      profileOverlayOpen: match[0]
                    }), React.createElement("section", {
                      id: "main"
                    }, children), React.createElement(Sidebar.make, { })), React.createElement(Layout$Footer, { }));
}

var make = Layout;

exports.favIcon = favIcon;
exports.Header = Header;
exports.Footer = Footer;
exports.make = make;
/* favIcon Not a pure module */
