'use strict';

var React = require("react");
var Social = require("./Social.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var AvatarPng = require("./avatar.png");
var ReactFontawesome = require("react-fontawesome");

var avatarImage = AvatarPng;

var services = /* array */[
  "github",
  "twitter",
  "xing",
  "linkedin",
  "rss"
];

function handleClick(evt) {
  evt.stopPropagation();
  return /* () */0;
}

var name = "Christoph Knittel";

var $$location = "Vienna, Austria";

var twitterUrl = Social.profiles["twitter"][/* url */1];

function renderSocialLink(icon, url) {
  return React.createElement("td", {
              key: icon
            }, React.createElement("a", {
                  className: "tooltip",
                  title: icon,
                  href: url,
                  target: "_blank"
                }, React.createElement(ReactFontawesome, {
                      name: icon
                    })));
}

function Profile(Props) {
  var profileOverlayOpen = Props.profileOverlayOpen;
  return React.createElement("aside", {
              className: profileOverlayOpen ? "card" : "",
              id: "profile"
            }, React.createElement("div", {
                  className: "inner profile-inner",
                  onClick: handleClick
                }, React.createElement("div", {
                      className: "base-info profile-block"
                    }, React.createElement("img", {
                          id: "avatar",
                          alt: "Avatar",
                          src: avatarImage
                        }), React.createElement("h2", {
                          id: "name"
                        }, name), React.createElement("h3", {
                          id: "title"
                        }, "CTO @ ", React.createElement("a", {
                              href: "http://cca.io/",
                              target: "_blank"
                            }, "cca.io"), React.createElement("br", undefined), React.createElement("br", undefined), React.createElement("a", {
                              href: "https://reasonml.github.io/",
                              target: "_blank"
                            }, "ReasonML"), " and ", React.createElement("a", {
                              href: "https://facebook.github.io/react-native/",
                              target: "_blank"
                            }, "React Native"), React.createElement("br", undefined), "enthusiast"), React.createElement("span", {
                          id: "location"
                        }, React.createElement(ReactFontawesome, {
                              name: "map-marker"
                            }), $$location), React.createElement("a", {
                          id: "follow",
                          href: twitterUrl,
                          target: "_blank"
                        }, "FOLLOW")), React.createElement("div", {
                      className: "profile-block social-links"
                    }, React.createElement("table", undefined, React.createElement("tbody", undefined, React.createElement("tr", undefined, services.map((function (service) {
                                        var match = Js_dict.get(Social.profiles, service);
                                        if (match !== undefined) {
                                          return renderSocialLink(service, match[/* url */1]);
                                        } else {
                                          return null;
                                        }
                                      }))))))));
}

var make = Profile;

exports.avatarImage = avatarImage;
exports.services = services;
exports.handleClick = handleClick;
exports.name = name;
exports.$$location = $$location;
exports.twitterUrl = twitterUrl;
exports.renderSocialLink = renderSocialLink;
exports.make = make;
/* avatarImage Not a pure module */
