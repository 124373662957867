'use strict';

var Utils = require("./Utils.js");
var React = require("react");
var Gatsby = require("gatsby");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function TagLink(Props) {
  var tag = Props.tag;
  var className = Props.className;
  var style = Props.style;
  var tmp = {
    to: Utils.getTagPath(tag),
    children: "#" + (String(tag) + "")
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  return React.createElement(Gatsby.Link, tmp);
}

var make = TagLink;

exports.make = make;
/* react Not a pure module */
