'use strict';

var SidebarQueryImpl = require("./SidebarQueryImpl");

var Tags = { };

var RecentPosts = { };

var make = SidebarQueryImpl.default;

exports.Tags = Tags;
exports.RecentPosts = RecentPosts;
exports.make = make;
/* make Not a pure module */
