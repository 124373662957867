'use strict';

var React = require("react");
var Gatsby = require("gatsby");
var TagLink = require("./TagLink.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReactFontawesome = require("react-fontawesome");

function Article$Title(Props) {
  var className = Props.className;
  var slug = Props.slug;
  var title = Props.title;
  if (slug !== undefined) {
    return React.createElement("h1", {
                itemProp: "name"
              }, React.createElement(Gatsby.Link, {
                    to: slug,
                    className: className,
                    children: title
                  }));
  } else {
    return React.createElement("h1", {
                className: className,
                itemProp: "name"
              }, title);
  }
}

var Title = {
  make: Article$Title
};

function Article$Meta$ArticleDate(Props) {
  var slug = Props.slug;
  var date = Props.date;
  var dateElement = React.createElement("time", {
        itemProp: "datePublished",
        dateTime: date
      }, date);
  return React.createElement("div", {
              className: "article-date"
            }, React.createElement(ReactFontawesome, {
                  name: "calendar"
                }), slug !== undefined ? React.createElement(Gatsby.Link, {
                    to: slug,
                    children: dateElement
                  }) : dateElement);
}

var ArticleDate = {
  make: Article$Meta$ArticleDate
};

function renderArticleCategories(categories) {
  if (categories !== undefined) {
    var categories$1 = categories;
    if (categories$1.length !== 0) {
      return React.createElement("div", {
                  className: "article-article-category"
                }, React.createElement(ReactFontawesome, {
                      name: "folder"
                    }), categories$1.join(" "));
    } else {
      return null;
    }
  } else {
    return null;
  }
}

function renderArticleTags(tags) {
  if (tags !== undefined) {
    var tags$1 = tags;
    if (tags$1.length !== 0) {
      return React.createElement("div", {
                  className: "article-tag"
                }, React.createElement(ReactFontawesome, {
                      name: "tag"
                    }), tags$1.map((function (tag) {
                        return React.createElement("span", {
                                    key: tag
                                  }, React.createElement(TagLink.make, {
                                        tag: tag
                                      }), " ");
                      })));
    } else {
      return null;
    }
  } else {
    return null;
  }
}

function Article$Meta(Props) {
  var slug = Props.slug;
  var frontmatter = Props.frontmatter;
  var tmp = {
    date: frontmatter.date
  };
  if (slug !== undefined) {
    tmp.slug = Caml_option.valFromOption(slug);
  }
  return React.createElement("div", {
              className: "article-meta"
            }, React.createElement(Article$Meta$ArticleDate, tmp), renderArticleCategories(Caml_option.undefined_to_opt(frontmatter.categories)), renderArticleTags(Caml_option.undefined_to_opt(frontmatter.tags)));
}

var Meta = {
  ArticleDate: ArticleDate,
  renderArticleCategories: renderArticleCategories,
  renderArticleTags: renderArticleTags,
  make: Article$Meta
};

var ArticlePageContext = { };

function Article$Navigation(Props) {
  var pageContext = Props.pageContext;
  var match = pageContext.prev;
  var match$1 = pageContext.next;
  return React.createElement("nav", {
              id: "article-nav"
            }, match !== undefined ? React.createElement(Gatsby.Link, {
                    to: match.slug,
                    id: "article-nav-newer",
                    className: "article-nav-link-wrap",
                    children: null
                  }, React.createElement("strong", {
                        className: "article-nav-caption"
                      }, "Newer"), React.createElement("div", {
                        className: "article-nav-title"
                      }, match.title)) : null, match$1 !== undefined ? React.createElement(Gatsby.Link, {
                    to: match$1.slug,
                    id: "article-nav-older",
                    className: "article-nav-link-wrap",
                    children: null
                  }, React.createElement("strong", {
                        className: "article-nav-caption"
                      }, "Older"), React.createElement("div", {
                        className: "article-nav-title"
                      }, match$1.title)) : null);
}

var Navigation = {
  make: Article$Navigation
};

function Article(Props) {
  var id = Props.id;
  var frontmatter = Props.frontmatter;
  var slug = Props.slug;
  var pageContext = Props.pageContext;
  var children = Props.children;
  var tmp = {
    className: "article-title",
    title: frontmatter.title
  };
  if (slug !== undefined) {
    tmp.slug = Caml_option.valFromOption(slug);
  }
  var tmp$1 = {
    frontmatter: frontmatter
  };
  if (slug !== undefined) {
    tmp$1.slug = Caml_option.valFromOption(slug);
  }
  return React.createElement("article", {
              className: "article",
              id: id,
              itemProp: "blogPost",
              itemScope: true
            }, React.createElement("div", {
                  className: "article-inner"
                }, React.createElement("header", {
                      className: "article-header"
                    }, React.createElement(Article$Title, tmp), React.createElement(Article$Meta, tmp$1)), React.createElement("div", {
                      className: "article-entry",
                      itemProp: "articleBody"
                    }, children)), pageContext !== undefined ? React.createElement(Article$Navigation, {
                    pageContext: Caml_option.valFromOption(pageContext)
                  }) : null);
}

var make = Article;

exports.Title = Title;
exports.Meta = Meta;
exports.ArticlePageContext = ArticlePageContext;
exports.Navigation = Navigation;
exports.make = make;
/* react Not a pure module */
